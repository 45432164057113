import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { CustomRow } from '../../../Components/CustomRow';
import { Col } from 'antd';
import Flex from '../../../Components/Flex';
import { Container } from '../../../Components/Container';
import { Aim, AimHolder, Black, Facts, OneStartUp, Spanone, StartUpContent, SvgHolder, VideoHolder } from './Style';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AimCard } from './AimCard';
import { factsData, serviceData } from '../../CustomData/ServiceStartUpData';
import { GradiantLetter } from '../../../Components/others/GradiantLetter';
import { FactsCard } from './FactsCard';
// import './sty.css'
import { NavBar } from '../../../Components/NavBar/NavBar';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import { Footer } from '../../Home/Partials/Footer';
import { ClientReview } from '../../Review/Partials/ClientReview';
import { WorkedWith } from '../../Home/Partials/WorkedWith';
import { HomeFirstSection } from '../../Home/Partials/Style';
import video from '../../../Assets/logoVideo.mp4';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const Startups = () => {
    const mainsection = useRef();
    const main = useRef();
    const facts = useRef();
    const blackPin = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [navSticky, setNavSticky] = useState(false);

    const setSticky = () => {
        if (window.scrollY >= 200) {
            setNavSticky(true);
        } else {
            setNavSticky(false);
        }
    };

    window.addEventListener('scroll', setSticky);

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const boxes = self.selector('.box');
            const title = self.selector('.aimtitle');
            const pining = self.selector('.sticky');

            // An array of x values for each box // Adjust these values based on your needs
            const xValues = [50, -50, 50];

            boxes.forEach((box, index) => {
                const startX = xValues[index];
                gsap.fromTo(
                    box,
                    {
                        opacity: 0, // Initial opacity value
                        x: startX, // Initial x value
                    },
                    {
                        opacity: 1, // Final opacity value
                        x: 0, // Final x value
                        scrollTrigger: {
                            trigger: box,
                            start: 'bottom 90%',
                            end: 'top 40%',
                            scrub: true,
                            // markers: true,
                        },
                    }
                );
            });

            gsap.to(pining, {
                scrollTrigger: {
                    trigger: '.blackPin',
                    start: '10% 70%',
                    end: 'bottom 70%',
                    // pin: pining,
                    // markers: true,
                    // scrub: true,
                    toggleClass: { targets: pining, className: 'active', add: true, remove: false },
                },
            });

            gsap.to(title, {
                opacity: 1,
                y: 100,
                scrollTrigger: {
                    trigger: '.aims',
                    start: 'top 70%',
                    end: 'bottom 70%',
                    // markers: true,
                    scrub: true,
                },
            });

            const pathSelector = "#path";
            const rocketSelector = "#rocket";

            gsap.to(rocketSelector, {
                transformOrigin: "50% 50%",
                scrollTrigger: {
                    trigger: pathSelector, // Use the path as the trigger
                    start: 'top 50%',
                    end: 'bottom 50%',
                    scrub: true,
                    // markers: true, // Set to true to show markers for debugging
                },
                motionPath: {
                    path: pathSelector,
                    align: pathSelector,
                    autoRotate: true,
                    alignOrigin: [0.5, 0.5],
                },
            });
        }, main); // <- Scope!

        const factx = gsap.context((self) => {
            const facts = self.selector('.facts');
            const title = self.selector('.facttitle');

            const yValues = [50, -50, 50, -50];

            facts.forEach((fact, index) => {
                const startY = yValues[index];
                gsap.fromTo(
                    fact,
                    {
                        opacity: 0, // Initial opacity value
                        y: startY, // Initial y value
                    },
                    {
                        opacity: 1, // Final opacity value
                        y: 0, // Final y value
                        scrollTrigger: {
                            trigger: fact,
                            start: 'bottom 90%',
                            end: 'top 40%',
                            scrub: true,
                            // markers: true,
                        },
                    }
                );
            });

            gsap.to(title, {
                opacity: 1,
                y: 0,
                scrollTrigger: {
                    trigger: '.facts',
                    start: 'top 70%',
                    end: 'bottom 70%',
                    // markers: true,
                    scrub: true,
                },
            });
        }, facts); // <- Scope!

        return () => {
            ctx.revert();
            factx.revert();
        }; // <- Cleanup!
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const navheader = self.selector('#nav-header');

            gsap.to(navheader, {
                scrollTrigger: {
                    trigger: '.worksection',
                    start: 'top 20px',
                    end: 'bottom 40px',
                    // markers: true,
                    scrub: 1,
                    toggleClass: { targets: navheader, className: 'dark', add: true, remove: false },
                },
            });

            gsap.to(navheader, {
                scrollTrigger: {
                    trigger: '.blackpin__wrapper',
                    start: 'top 20px',
                    end: 'bottom 40px',
                    // markers: true,
                    scrub: 1,
                    toggleClass: { targets: navheader, className: 'dark', add: true, remove: false },
                },
            });
        }, mainsection); // <- Scope!

        return () => {
            ctx.revert();
        }; // <- Cleanup!
    }, []);

    return (
        <div ref={mainsection} className='scroll-container'>
            <Helmet>
                <title>IdeauxTech Best Software companies in Nagercoil | About us </title>
                <meta name="description" content="Leading web development company in Nagercoil specializing in custom software, web and mobile app development, and IT solutions."/>
                <meta name="keywords" content="Startups, Software Development, software engineer, custom software development, develop mobile app"/>
                <meta name="robots" content="index, follow"/>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="language" content="English"/>
                <link rel="canonical" href="https://ideauxtech.com/aboutus"/>
                <meta name="google-site-verification" content="NkqHILqXsZqlohw55r9V2v8zoAdW-h1g0C1L_g1jtwM" />
            </Helmet>
            <NavBar sticky={navSticky} />

            <HomeFirstSection>
                <Container>
                    <CustomRow style={{ margin: '25px ' }} space={[24, 24]}>
                        <Col span={12} md={2}></Col>
                        <Col xs={24} span={12} md={17}>
                            <OneStartUp>
                                <h3>About one of the <span>leading software companies</span> in Nagercoil</h3>
                            </OneStartUp>
                        </Col>
                    </CustomRow>

                    <CustomRow style={{ marginTop: '20px' }} space={[24, 24]}>
                        <Col span={24} lg={12}>
                            <VideoHolder>
                                <video src={video} loop muted autoPlay/>
                            </VideoHolder>
                        </Col>

                        <Col span={24} lg={12}>
                            <StartUpContent>
                                <p>
                                IDEAUX is one of the best software companies in Nagercoil, founded in 2022. We provide software development services, leveraging cutting edge technologies web design services for small business to achieve their unique goals with tailored solutions.<br/><br/>

                                    At IDEAUX, we specialize in custom web solutions and responsive web design, offering result oriented strategies that help your business grow. Our team focuses on user-centered design, scalable digital experiences.<br /><br />

                                    We are a professional, agile, and open-minded team, committed to delivering the best solutions that fit your business needs and budget. With our round-the-clock support and a flexible pricing model, we ensure you get optimal results tailored to your unique requirements.<br /><br />

                                    Achieve your business goals and stay ahead of the competition with IDEAUX by your side.
                                </p>
                            </StartUpContent>
                        </Col>
                    </CustomRow>
                </Container>
                <Black ref={blackPin} className='blackpin__wrapper'>
                    <Container ref={main}>
                        <SvgHolder>
                            <svg xmlns="http://www.w3.org/2000/svg" width="306" height="777" viewBox="0 0 306 777" fill="none">
                                <path id="path" d="M304.94 2.56737e-06C304.942 143.046 232.217 212.5 90.6431 313C-50.9308 413.5 -49.0659 742.5 274.44 776.5" stroke="white" />
                            </svg>
                            <svg id="rocket" xmlns="http://www.w3.org/2000/svg" width="58" height="57" viewBox="0 0 58 57" fill="none">
                                <mask id="path-1-inside-1_1_4" fill="white">
                                    <path d="M57.329 26.5554L2.34458 0.24513L13.7567 27.7866M57.329 26.5554L0.768591 56.0174L13.7567 27.7866M57.329 26.5554L13.7567 27.7866Z" />
                                </mask>
                                <path d="M57.329 26.5554L2.34458 0.24513L13.7567 27.7866M57.329 26.5554L0.768591 56.0174L13.7567 27.7866M57.329 26.5554L13.7567 27.7866Z" fill="white" />
                                <path d="M57.329 26.5554L59.1769 30.103C60.5211 29.4028 61.3541 28.003 61.3284 26.4875C61.3027 24.9721 60.4227 23.6014 59.0555 22.9472L57.329 26.5554ZM2.34458 0.24513L4.07111 -3.36306C2.57638 -4.0783 0.795397 -3.79787 -0.407219 -2.65791C-1.60984 -1.51794 -1.98507 0.245499 -1.35075 1.77633L2.34458 0.24513ZM0.768591 56.0174L-2.86528 54.3456C-3.58 55.8991 -3.22811 57.7352 -1.98965 58.9143C-0.751197 60.0935 1.09988 60.355 2.6165 59.565L0.768591 56.0174ZM59.0555 22.9472L4.07111 -3.36306L0.618041 3.85333L55.6025 30.1636L59.0555 22.9472ZM-1.35075 1.77633L10.0614 29.3178L17.452 26.2554L6.0399 -1.28607L-1.35075 1.77633ZM55.4811 23.0078L-1.07932 52.4699L2.6165 59.565L59.1769 30.103L55.4811 23.0078ZM4.40246 57.6892L17.3906 29.4584L10.1228 26.1148L-2.86528 54.3456L4.40246 57.6892ZM57.216 22.557L13.6437 23.7882L13.8697 31.785L57.442 30.5538L57.216 22.557Z" fill="white" mask="url(#path-1-inside-1_1_4)" />
                            </svg>
                        </SvgHolder>

                        <div className='blackPin'>
                            <span className='sticky'>Our Aim.</span>
                            <div className='aims'>
                                <CustomRow space={[24, 24]}>
                                    <Col span={24} md={24}>
                                        <Flex center={'true'} className='aimtitle' style={{ opacity: 0, transform: "translateY(-100px)" }}>
                                            <Aim>Our <GradiantLetter size={'48px'}>Aim</GradiantLetter> is</Aim>
                                        </Flex>
                                    </Col>
                                </CustomRow>
                            </div>
                            <Container>
                                <AimHolder>
                                    <AimCard data={serviceData} />
                                </AimHolder>
                            </Container>
                        </div>
                    </Container>
                    <Container ref={facts}>
                        <div className='facts'>
                            <Flex center={'true'} className='facttitle' style={{ opacity: 0, transform: "translateY(-100px)" }}>
                                <Facts>Amazing <GradiantLetter size={'48px'}>Facts</GradiantLetter> about <Spanone>Ideaux</Spanone></Facts>
                            </Flex>
                        </div>
                        <Container>
                            <FactsCard data={factsData} />
                        </Container>
                    </Container>
                </Black>
                <WorkedWith />
                <ClientReview />
                <Footer />
            </HomeFirstSection>
        </div>
    );
};

export default Startups;

