import Car1 from '../../Assets/Images/HomeCarousel/Carousel1.webp'
import Car2 from '../../Assets/Images/HomeCarousel/Carousel2.webp'
import Car3 from '../../Assets/Images/HomeCarousel/Carousel3.webp'
import Car4 from '../../Assets/Images/HomeCarousel/Carousel4.webp'
import Car5 from '../../Assets/Images/HomeCarousel/Carousel5.webp'
import Car6 from '../../Assets/Images/HomeCarousel/Carousel6.webp'
import Car7 from '../../Assets/Images/HomeCarousel/Carousel7.webp'
import Car8 from '../../Assets/Images/HomeCarousel/Carousel8.webp'
import { softwareLogo } from '../../Assets/Images/HomeSoftwareLogo'

export const CarouselImg = [
    {
        key: '1',
        name: Car1,
        title:'INSVITY Website',
    },
    {
        key: '2',
        name: Car2,
        title:'Ajdin Ship Management Website',
    },
    {
        key: '3',
        name: Car3,
        title:'Brand Partners Website',
    },
    {
        key: '4',
        name: Car4,
        title:'SmileIt Website',
    },
    {
        key: '5',
        name: Car5,
        title:'Zayit Website',
    },
    {
        key: '6',
        name: Car6,
        title:'Pugal Event Management Website',
    },
    {
        key: '7',
        name: Car7,
        title:'Aari World Website',
    },
    {
        kay: '8',
        name: Car8,
        title:'Simson Website',
    },
]

export const homeSoftwareTitle = [
    {
        key: '1',
        title: 'Web Apps',
    },
    {
        key: '2',
        title: 'Mobile Apps',
    },
    {
        key: '3',
        title: 'Customized Softwares',
    },
    {
        key: '4',
        title: 'Digital Marketing',
    },
    {
        key: '5',
        title: 'Testing',
    },
    {
        key: '6',
        title: 'DevOps',
    },
]

export const homeSoftwareContent = [
    {
        key: '1',
        name: "Web Apps",
        content: 'Have you ever wished for a creative web page design that goes beyond the ordinary, offering a truly unique and tailored experience? At IdeauxTech, we take pride in being a leading website design company in Nagercoil, delivering innovative web applications that stand out from the crowd. Whether you need a custom website, a powerful web app, or a seamless user experience, we bring your vision to life with cutting-edge technology and creative solutions. And can be accessed through Google as web designers near me to get in touch with us.',
        lists: [
            {
                key: '1',
                title: 'Front-end Development'
            },
            {
                key: '2',
                title: 'Web Application'
            },
            {
                key: '3',
                title: 'Custom Application'
            },
        ]
    },
    {
        key: '2',
        name: "Mobile Apps",
        content: 'We focus on developing robust and intuitive mobile applications customized to meet your business requirements and also we are the best software comapany in nagercoil. Our skilled developers create innovative applications that enhance engagement, improve efficiency, and elevate revenue. No matter if youre seeking a mobile solution for e-commerce, healthcare, finance, or another sector, we provide high-performance applications aimed at improving user experience and optimizing operations. By emphasizing innovation, scalability, and security, we guarantee that your app remains competitive while fulfilling the changing needs of your customers',
        lists: [
            {
                key: '1',
                title: 'Native IOS App'
            },
            {
                key: '2',
                title: 'Native Android App'
            },
            // {
            //     key: '3',
            //     title: 'Flutter App'
            // },
        ]
    },
    {
        key: '3',
        name: "Customized Softwares",
        content: 'We create customized software solutions aimed at fulfilling your specific business requirements. Whether it’s CRM, ERP, automation solutions, or specialized applications, our flexible and secure software improves efficiency, simplifies operations, and fosters growth. Through smooth integration and continuous assistance, we guarantee that your business remains at the forefront with cutting-edge technology.',
        lists: [
            {
                key: '1',
                title: 'Industry specific solutions'
            },
            {
                key: '2',
                title: 'Collaborative Development Process'
            },
            {
                key: '3',
                title: 'Integration Capabilities'
            },
            {
                key: '4',
                title: 'User Interface (UI) Design'
            },
            {
                key: '5',
                title: 'Quality Assurance and Testing'
            },
        ]
    },
    {
        key: '4',
        name: "Digital Marketing",
        content: 'We specialize in crafting tailored digital marketing strategies that amplify your brands online presence and we are the top digital marketing company in nagercoil. Our comprehensive services encompass SEO, social media management, content creation, and targeted advertising, all designed to engage your audience effectively and drive measurable growth. ',
        lists: [
            {
                key: '1',
                title: 'Social Media Marketing'
            },
            {
                key: '2',
                title: 'Email Marketing'
            },
            {
                key: '3',
                title: 'Content Marketing'
            },
        ]
    },
    {
        key: '5',
        name: "Testing",
        content: 'We guarantee your software operates seamlessly through our thorough testing services. We detect and resolve issues prior to deployment, covering functional and performance testing as well as security and usability evaluations. Our objective is to provide top-notch, flawless software that improves user experience and boosts business productivity.',
        lists: [
            {
                key: '1',
                title: 'Manual web testing'
            },
            {
                key: '2',
                title: 'Manual Mobile testing'
            },
            {
                key: '3',
                title: 'Automation testing'
            },
        ]
    },
    {
        key: '6',
        name: "DevOps",
        content: 'We optimize software development and IT operations through our skilled DevOps services. Through the automation of workflows, boosting collaboration, and optimizing infrastructure, we assist businesses in attaining quicker deployments, enhanced scalability, and greater reliability. Our DevOps offerings encompass CI/CD setup, cloud integration, infrastructure automation, and performance tracking to guarantee smooth software delivery',
        lists: [
            {
                key: '1',
                title: 'Version controlling'
            },
            {
                key: '2',
                title: 'System testing'
            },
            {
                key: '3',
                title: 'Infra Set-up'
            },
            {
                key: '4',
                title: 'Dynamic Provisioning'
            },
            {
                key: '5',
                title: 'Configuration'
            },
            {
                key: '6',
                title: 'Deployment'
            },
            {
                key: '7',
                title: 'Up-time Monitoring'
            },
        ]
    }
]

export const homeSoftwareIcons = [
    {
        key: '1',
        title: 'Web Apps',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Web1,
                title:'HTML Logo',
            },
            {
                key: '2',
                icon: softwareLogo.Web2,
                title:'CSS Logo',
            },
            {
                key: '3',
                icon: softwareLogo.Web3,
                title:'Bootstrap Logo',
            },
            {
                key: '4',
                icon: softwareLogo.Web4,
                title:'Laravel Logo',
            },
            {
                key: '5',
                icon: softwareLogo.Web5,
                title:'React Js Logo',
            },
        ]
    },
    {
        key: '2',
        title: 'Mobile Apps',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Mobile1,
                title:'React Native Logo',
            },
        ]
    },
    {
        key: '3',
        title: 'Customized Softwares',
        lists: [
            {
                key: '2',
                icon: softwareLogo.App1,
                title:'Django Logo',
            },
            {
                key: '3',
                icon: softwareLogo.App2,
                title:'Java Logo',
            },
            {
                key: '4',
                icon: softwareLogo.App3,
                title:'Laravel Logo',
            },
            {
                key: '5',
                icon: softwareLogo.App4,
                title:'React Logo',
            },
            {
                key: '6',
                icon: softwareLogo.App5,
                title:'Vite Logo',
            },
        ]
    },
    {
        key: '4',
        title: 'Digital Marketing',
        lists: [
            {
                key: '3',
                icon: softwareLogo.Marketing1,
                title:'Facebook logo',
            },
            {
                key: '4',
                icon: softwareLogo.Marketing2,
                title:'Instagram Logo',
            },
            {
                key: '5',
                icon: softwareLogo.Marketing3,
                title:'LinkedIn logo',
            },
            {
                key: '6',
                icon: softwareLogo.Marketing4,
                title:'Whatsapp Logo',
            },
        ]
    },
    {
        key: '5',
        title: 'Testing',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Test1,
                title:'Apachejmeter Logo',
            },
            {
                key: '2',
                icon: softwareLogo.Test2,
                title:'Appium Logo',
            },
            {
                key: '3',
                icon: softwareLogo.Test3,
                title:'Postman Logo',
            },
            {
                key: '4',
                icon: softwareLogo.Test4,
                title:'Selenium logo',
            },
        ]
    },
    {
        key: '6',
        title: 'DevOps',
        lists: [
            {
                key: '11',
                icon: softwareLogo.Dev6,
                title:'AWS Logo',
            },
            {
                key: '1',
                icon: softwareLogo.Dev1,
                title:'Ansible Logo',
            },
            {
                key: '2',
                icon: softwareLogo.Dev2,
                title:'Docker Logo',
            },
            {
                key: '3',
                icon: softwareLogo.Dev3,
                title:'Jenkins Logo',
            },
            {
                key: '4',
                icon: softwareLogo.Dev4,
                title:'Kubernetes Logo',
            },
            {
                key: '5',
                icon: softwareLogo.Dev5,
                title:'Nginx Logo',
            },
        ]
    },

]