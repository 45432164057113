'use client'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
    HomeCarouselwrapper,
    HomeCarouselHolder,
    CarousalHolder,
    HomeFirstSection,
    HomeFirstSectionPara,
    HomeSoftwareWrapper,
    HomeSoftwareContentHolder,
    HomeSoftwareRow,
    HomeSoftwareTitle,
    HomeSoftwareContainer,
    HomeSoftewareCard,
    HomeSoftwareContentWrapper,
    HomeSoftwareStickyHeader,
    HomeSoftwareIconHolder,
    HomeCarouselBackLayer,
    SwiperContainer,
    HomeCarouselwrapperSecond,
} from './Style'
import { Fade } from "react-awesome-reveal";
import styled, { keyframes } from 'styled-components'
import { Container } from '../../../Components/Container';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useLayoutEffect } from 'react';
import { GradiantLetter } from '../../../Components/others/GradiantLetter';
import { NavBar } from '../../../Components/NavBar/NavBar';
import { SwiperCard } from './SwiperCard';
import { gsap } from 'gsap';
import {ScrollTrigger}  from 'gsap/ScrollTrigger';
import { Footer } from './Footer';
import { homeSoftwareContent, homeSoftwareIcons, homeSoftwareTitle } from '../../CustomData/Data';
import { ClientReview } from '../../Review/Partials/ClientReview';
import { WorkedWith } from './WorkedWith';
// import { ScrollSmoother } from 'gsap/';

const changeColorAnimation = keyframes`
  0% {
    color: black;
  }

  50% {
    color: grey;
  }

  100% {
    color: black;
  }
`;

export const Design = styled.p`
font-family: 'Raleway', sans-serif;
font-weight: 700;
font-size: 62px;
line-height: 82px;
letter-spacing: -1.61 px;

animation: ${changeColorAnimation} 3s infinite;


@media (max-width: 768px) {
  font-size: 42px;
  margin-left: 150px;
  }


`

export const Head = styled.div`
  font-weight: 600;
  font-size: 25px;

  @media (min-width: 768px) {
    text-align: center;
    font-size: 68px;
  }

  @media (min-width: 499px) and (max-width: 993px) {
    text-align: center;
    font-size: 54px;
  }
`;


gsap.registerPlugin(ScrollTrigger)

export const HomePage = ({ }) => {


    const [activeKey, setActiveKey] = useState(1)

    useEffect(() => {
        Aos.init();
    }, []);

    const targetRefs = useRef([]);

    const homeSoftwareWrapper = useRef();  // ----  What we do


    const [navSticky, setNavSticky] = useState(false)


    const setSticky = () => {
        if (window.scrollY >= 200) {
            setNavSticky(true)
        } else {
            setNavSticky(false)
        }
    }

    window.addEventListener('scroll', setSticky)

    useEffect(() => {
        const main = document.getElementById('main-container')

        const ctx = gsap.context((self) => {
            const navheader = self.selector('#nav-header');

            gsap.to(navheader,
                {
                    ease: 'easeInOut',
                    scrollTrigger: {
                        trigger: '.worksection',
                        start: 'top 20px',
                        end: 'bottom 40px',
                        // markers: true,
                        scrub: 1,
                        toggleClass: { targets: navheader, className: 'dark', add: true, remove: false },
                    },
                    // className: '+=new-class',
                })

        }, main); // <- Scope!


        return () => {
            ctx.revert();
        }; // <- Cleanup!
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useLayoutEffect(() => {
        const ctrlx = gsap.context((self) => {
            const pining = self.selector('.sticky');

            const piningicon = self.selector('.stickyicon')

            const cards = self.selector('.homedetails');

            cards.forEach((card, index) => {
                // const startX = xValues[index];
                const startX = homeSoftwareTitle[index];
                targetRefs.current[index + 1] = React.createRef();

                gsap.to(card,
                    {
                        duration: 1,
                        ease: "elastic",
                        scrollTrigger: {
                            trigger: card,
                            start: 'top 15%',
                            end: 'bottom 30%',
                            // pin: pining,
                            // pinSpacing: true,
                            // scrub: true,
                            // markers: true,
                            onToggle: ({ isActive }) => {
                                // This function will be called when the section is triggered or untriggered
                                if (isActive) {
                                    setActiveKey(index + 1);
                                }
                            },
                        },
                    });
            });

            gsap.to(pining, {
                // opacity: 0,
                // y: 50,
                zIndex: 20,
                duration: 4,
                ease: "none",
                scrollTrigger: {
                    trigger: '.homesoftware',
                    start: 'top 20%',
                    end: '97% 80%',
                    pin: pining,
                    // pinSpacing: true,
                    // markers: true,
                    scrub: true,
                },
            })


            gsap.from(piningicon, {
                // opacity: 1,
                // y: 0,
                scrollTrigger: {
                    trigger: '.homesoftware',
                    start: 'top 15%',
                    end: 'bottom 95%',
                    pin: piningicon,
                    // pinSpacing: true,
                    // markers: true,
                    scrub: true,
                },
            })


            // homedetails
        }, homeSoftwareWrapper); // <- Scope!

        return () => {
            ctrlx.revert();
        }; // <- Cleanup!
    }, []);


    const GoToIndex = (id) => {

        const targetRef = targetRefs.current[id];
        if (targetRef && targetRef.current) {
            const triggerStart = window.innerHeight * 0.15; // Assuming trigger starts at top 15%
            const scrollToPosition = targetRef.current.offsetTop;
            targetRef.current.scrollIntoView({
                // top: scrollToPosition,
                block: 'start',
                inline: "nearest",
                behavior: 'smooth',
            });
        }
    }
    return (

        <div id={'main-container'}
            className="main-container"
            data-scroll-container>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="title" content="Web design company in nagercoil - Ideauxtech Pvt Ltd" />
      <meta name="description" content="IdeauxTech is the web design company in Nagercoil because we offer high-quality, reasonably priced websites with appealingdesigns. Start Your Project With Us Now!" />
      <meta name="keywords" content="Web design, web development company, software development, software development company, website development, website development services, digital marketing agency, digital marketing services, digital marketing website, digital marketing company"/>
      <meta name="robots" content="index, follow"/>
      <link rel="canonical" href="https://ideauxtech.com/" />
      <meta name="language" content="English" />
      <meta name="author" content="IdeauxTech Pvt Ltd" />
      <title>Web design company in nagercoil - Ideauxtech Pvt Ltd</title>
      <meta name="google-site-verification" content="NkqHILqXsZqlohw55r9V2v8zoAdW-h1g0C1L_g1jtwM" />
    </Helmet>
        
    
            <NavBar sticky={navSticky} />
            <div data-scroll data-x1-speed={10}>
                <HomeFirstSection>
                    
                    <Fade>
                        <Container>
                            <HomeFirstSectionPara>
                                <h2 className='weare'>We Are..</h2>
                                <p className='home__content'>One of the Web design company in nagercoil and the entire world, <GradiantLetter>IdeauxTech</GradiantLetter> is committed to providing businesses of all sizes with high-quality solutions. IdeauxTech enables companies of all sizes to build a solid online presence and experience expansion.</p>
                            </HomeFirstSectionPara>
                        </Container>
                    </Fade>

                    {/* ========= Carousal */}
                    <HomeCarouselHolder>
                        <HomeCarouselBackLayer> </HomeCarouselBackLayer>
                        <HomeCarouselwrapper>
                            <CarousalHolder>
                                <SwiperCard />
                            </CarousalHolder>
                        </HomeCarouselwrapper>
                    </HomeCarouselHolder>

                    {/* ========= Carousal */}

                    {/* <HomeCarouselHolder>
                        <HomeCarouselwrapperSecond>

                        <SwiperCard />
                        </HomeCarouselwrapperSecond>
                        // {/* <SwiperContainer></SwiperContainer> */}
                    {/* </HomeCarouselHolder> */}

                    {/* ========= What we Do */}

                    {/* <SoftwareHolding /> */}

                    <HomeSoftwareContainer >
                        <Container>
                            <HomeSoftwareWrapper>
                                <HomeSoftwareContentHolder ref={homeSoftwareWrapper}>
                                    <HomeSoftwareRow>

                                        <HomeSoftwareIconHolder className='stickyicon'>
                                            {
                                                homeSoftwareIcons.map((item) => (
                                                    <div key={item.key} className={`icon__holder ${item.key == activeKey ? 'active' : ''}`}>
                                                        {
                                                            item.lists.map((list) => (
                                                                <img src={list.icon} key={list.key} alt={list?.title} title={list?.title} />
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </HomeSoftwareIconHolder>

                                        <HomeSoftwareTitle className='sticky'>
                                            {
                                                homeSoftwareTitle.map((item) => (
                                                    <h3 key={item.key} className={item.key == activeKey ? 'active' : ''} onClick={() => GoToIndex(item.key)}>{item.title}</h3>
                                                ))
                                            }
                                        </HomeSoftwareTitle>

                                        {/* <ResHomeSoftwareTitle>
                                            {
                                                homeSoftwareTitle.map((item) => (
                                                    <h1 key={item.key}>{item.title}</h1>
                                                ))
                                            }
                                        </ResHomeSoftwareTitle> */}
                                    </HomeSoftwareRow>

                                    <HomeSoftwareRow className='homesoftware'>
                                        <HomeSoftwareContentWrapper>

                                            <HomeSoftwareStickyHeader>
                                                <h2>What we do.</h2>
                                                <p>We believe in perfection by design and
                                                    performance by default.</p>
                                            </HomeSoftwareStickyHeader>
                                            {
                                                homeSoftwareContent.map((item) => (
                                                    <HomeSoftewareCard key={item.key} className='homedetails' ref={targetRefs.current[item.key]}>

                                                        <h2><GradiantLetter>{item.name}</GradiantLetter></h2>
                                                        <p>{item.content}</p>

                                                        <ul>
                                                            {
                                                                item.lists.map((list) => (
                                                                    <li key={list.key}>{list.title}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </HomeSoftewareCard>
                                                ))
                                            }
                                        </HomeSoftwareContentWrapper>
                                    </HomeSoftwareRow>
                                </HomeSoftwareContentHolder>
                            </HomeSoftwareWrapper>
                        </Container>

                    </HomeSoftwareContainer>

                    {/* <WorkedWithLogo /> */}
                    <WorkedWith />
                    
                    <ClientReview />

                    <Footer />
                </HomeFirstSection>
            </div>
        </div>

    )
}
