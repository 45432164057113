import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { ListCard, Controls } from './style'
import arrow from '../../../Assets/Images/Others/EnterArrow.svg'
import { CustomCursorContext } from '../../../Components/others/CustomCursor/context/CustomCursorContext'

export const Requirements = ({ data, update, selected, count, updatedata }) => {

    const [btnShow, setBtnShow] = useState(false)

    const { type, setType } = useContext(CustomCursorContext);
    useEffect(() => {
        if(selected.length != 0){   
            setBtnShow(true)
        } else{
            setBtnShow(false)
        }
    }, [selected])
    const SelectedRequirt = () => {
        if (selected.length != 0) {
            count(1)
            updatedata();
        } 
    }
    return (
        <ListCard>
            <Helmet>
    <title>Contact us | IdeauxTech Pvt Ltd</title>
    <meta name="description" content="Learn more about IdeauxTech, a leading web development company dedicated to creating custom websites that help businesses grow online and connect with customers." />
    <meta property="og:title" content="Contact us | IdeauxTech Pvt Ltd" />
    <meta property="og:description" content="Learn more about IdeauxTech, a leading web development company dedicated to creating custom websites that help businesses grow online and connect with customers." />
    <link rel="canonical" href="https://ideauxtech.com/letstalk" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="robots" content="index, follow"/>
    <meta name="google-site-verification" content="NkqHILqXsZqlohw55r9V2v8zoAdW-h1g0C1L_g1jtwM" />
</Helmet>

            <h1>Hey! 👋🏻 Tell us your requirements</h1>

            <div className='req'>
                {
                    data.map((item, index) => (
                        <span key={item.key} onMouseEnter={() => setType('button')}
                        onMouseLeave={() => setType('default')} onClick={() => update(item)} className={selected?.some((selectedItem) => selectedItem?.key === item?.key) ? 'active' : ''}>{item.name}</span>
                    ))
                }
            </div>

            {
                btnShow && (
                    <Controls>
                        <span className='btn' onClick={SelectedRequirt}>ok</span>
                        <div>Press<span className='text'>Enter</span><img src={arrow} title='Enter Arrow' alt="Enter Arrow" />to continue</div>
                    </Controls>
                )
            }
        </ListCard>
    )
}
