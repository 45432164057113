import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Container } from '../../../Components/Container'
import 'aos/dist/aos.css';
import {
    ServiceWrapper,
    ServiceContentWrapper,
    ServiceContentHolder,
    ServiceContentRow,
    ServiceCardHolder,
    ServiceCard,
    ServiceListHolder,
    ServiceList,
    ResServiceContentRow,
    ServiceTitle,
    ResServiceTitle,
    ServiceRowHolder,
    ServiceRowContentRow,
} from './Style'
import { GradiantLetter } from '../../../Components/others/GradiantLetter'
import { Footer } from '../../Home/Partials/Footer'
import { NavBar } from '../../../Components/NavBar/NavBar'
import { serviceData1, serviceData2, serviceList } from '../../CustomData/ServiceData'
import downArrow from '../../../Assets/Images/Others/DownArrow.svg'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';

gsap.registerPlugin(ScrollTrigger);

const ServiceNew = () => {

    const [navSticky, setNavSticky] = useState(false)

    const serviceWrapper = useRef();
    const contentRef = useRef();

    const scrollRef = useRef();

    const setSticky = () => {
        if (window.scrollY >= 200) {
            setNavSticky(true)
        } else {
            setNavSticky(false)
        }
    }


    window.addEventListener('scroll', setSticky)

    // useEffect(() => {
    //     const scroll = new LocomotiveScroll({
    //         el: scrollRef.current,
    //         smooth: true,
    //     });

    //     return () => {
    //         scroll.destroy();
    //     }
    // }, [])


    // onToggle: ({ isActive }) => {
    //     // This function will be called when the section is triggered or untriggered
    //     if (isActive) {
    //         // Modify content when the element is triggered
    //         contentRef.current.innerHTML = 'Design workshops, user research & UI design'; // Modify this line based on your needs
    //     } else {
    //         // Restore content when the element is untriggered
    //         contentRef.current.innerHTML = 'Original Content'; // Modify this line based on your needs
    //     }
    // },

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const pining = self.selector('.designPin');

            const wrapper1 = self.selector('.design');
            const wrapper2 = self.selector('.ecommerce');

            const pinValues = ['Design workshops, user research & UI design', 'Sustainable solutions for your online presence'];
            const pinWrapperValues = [wrapper1, wrapper2];

            gsap.to(pining, {
                // y: 50,
                scrollTrigger: {
                    trigger: '.service__wrapper',
                    start: 'top 40%',
                    end: 'bottom 70%',
                    pin: pining,
                    // markers:true,
                    scrub: true
                },
            });

            pinWrapperValues.forEach((row, index) => {
                const startX = pinWrapperValues[index];

                const title = self.selector('#title');

                gsap.to(row,
                    {
                        scrollTrigger: {
                            trigger: startX,
                            start: 'top 40%',
                            end: 'bottom 10%',
                            // markers: true,
                            toggleClass: { targets: title, className: index === 0 ? 'active1' : 'active2', },
                            scrub: true,
                        },
                    });
            });

            //     pinWrapperValues.forEach((row, index) => {
            //         const startX = pinWrapperValues[index];
            //         const pinX = pinValues[index];
            //         gsap.to(row,
            //             {
            //                 scrollTrigger: {
            //                     trigger: startX,
            //                     start: 'top 40%',
            //                     end: 'bottom 30%',
            //                     markers: true,
            //                     onToggle: ({ isActive }) => {
            //                         if (isActive) {
            //                           contentRef.current.innerHTML = pinValues[index];
            //                         }
            //                       },
            //                     scrub: true,
            //                 },
            //             });
            //     });
        }, serviceWrapper); // <- Scope!

        return () => {
            ctx.revert();
        }; // <- Cleanup!
    }, []);


    return (
        <div ref={scrollRef} className='scroll-container'>
            <Helmet>
                <title>Top Web Development Services in Nagercoil | IdeauxTech Pvt Ltd</title>
                <meta charSet="utf-8" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Get top-notch web development services with IdeauxTech. Custom websites tailored to your business needs in Nagercoil" />
                <meta name="keywords" content="web development company,web design,website designer near me,website designers,web page design,website development company,web developers near me,software development company,app development software,software development outsourcing companies,software development,digital marketing agency,digital marketing company,digital marketing near me" />
                <link rel="canonical" href="https://ideauxtech.com/service" /> 
                <meta name="robots" content="index, follow"/>
            </Helmet>

            {/* <Container> */}
            <NavBar sticky={navSticky} hold={'true'} dark={'true'} />
            {/* </Container> */}

            <ServiceWrapper>
                <div className='leftWrapper'>
                    <h2>
                        <GradiantLetter>Services</GradiantLetter>
                    </h2>
                    <p>We specialize in creating custom Web Development Services of top-class strategy,
                        design and development.
                        With IDEAUX, get all-inclusive technology solutions for  your business needs.</p>
                </div>

                <div className='rightWrapper'>
                    <div className='arrow'><img src={downArrow} alt="DownArrow" title='DownArrow'/></div>
                    <div className='listholder'>
                        <div className='placeleft'>Overview</div>
                        {
                            serviceList.map((item) => (
                                <h2 key={item.key}>{item.name}</h2>
                            ))
                        }
                    </div>
                </div>
            </ServiceWrapper>

            <Container >
                <ServiceContentWrapper data-scroll data-scroll-speed={'0'} >
                    <ServiceContentHolder ref={serviceWrapper} className='rows'>
                        <ServiceContentRow>
                            <ServiceTitle className='designPin' ref={contentRef}>
                                <h2 id='title' className='active'>
                                    <span>Design workshops, user research & UI design</span>
                                    <span>Sustainable solutions for your online presence</span>
                                </h2>
                            </ServiceTitle>
                        </ServiceContentRow>

                        <ServiceRowHolder className='service__wrapper'>
                            <ServiceRowContentRow className='design'>
                                <ResServiceTitle >
                                    <h2>Design workshops, user research & UI design</h2>
                                </ResServiceTitle>

                                <ServiceCardHolder>
                                    {
                                        serviceData1.map((item) => (
                                            <ServiceCard key={item.key}>
                                                <h2>{item.title}</h2>
                                                <p>{item.content}</p>
                                                {/* <ServiceListHolder >
                                                    {
                                                        item.list.map((list) => (
                                                            <ServiceList key={list.key} underline={list.under}>{list.title}</ServiceList>
                                                        ))
                                                    }
                                                </ServiceListHolder> */}

                                                <img src={item.img} alt={item?.title} title={item?.title} />
                                            </ServiceCard>
                                        ))
                                    }
                                </ServiceCardHolder>
                            </ServiceRowContentRow>

                            <ServiceRowContentRow className='ecommerce'>

                                <ResServiceTitle >
                                    <h2>Sustainable solutions for your online presence</h2>
                                </ResServiceTitle>

                                <ServiceCardHolder>
                                    {
                                        serviceData2.map((item) => (
                                            <ServiceCard key={item.key}>
                                                <h2>{item.title}</h2>
                                                <p>{item.content}</p>
                                                {/* <ServiceListHolder >
                                                    {
                                                        item.list.map((list) => (
                                                            <ServiceList key={list.key} underline={list.under}>{list.title}</ServiceList>
                                                        ))
                                                    }
                                                </ServiceListHolder> */}

                                                <img src={item.img} alt={item?.title} title={item?.title} />
                                            </ServiceCard>
                                        ))
                                    }
                                </ServiceCardHolder>
                            </ServiceRowContentRow>
                        </ServiceRowHolder>

                    </ServiceContentHolder>
                    {/* <ServiceContentHolder ref={serviceEcommerceWrapper} className='rows'>
                        <ServiceContentRow >
                            <ServiceTitle className='ecommercepin'>
                                <h1>Sustainable solutions for your online presence</h1>
                            </ServiceTitle>

                        </ServiceContentRow>

                    </ServiceContentHolder> */}
                </ServiceContentWrapper>
            </Container>
            <Footer />
        </div>
    )
}

export default ServiceNew